import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { toColor } from 'react-color-palette';
import i18next from 'i18next';
import MainPage from './MainPage';
import ShadowSimulator from './ShadowSimulatorPage';
import SceneComparePage from './SceneComparePage';
import UserProfilePage from './UserProfilePage';
import { useData } from '../components/DataProvider';
import EmbedOptionsPage from './EmbedOptionsPage';
import PasswordResetPage from './PasswordResetPage';
import api from '../api/api';
import LoadingSpinner from '../components/Helper/LoadingSpinner';
import { getSelectedItemById } from '../util/util';
import '../util/i18n';

export default function App() {
  const {
    setJsonData,
    setCardsMap,
    setSelectedItems,
    setFilterItems,
    setSelectedItemsRight,
    setUserData,
    setUserId,
    setSelectColor,
    setSelectedSceneIndex,
    user,
    setBanner,
    setPrivacyPolicy,
    setAllScenes,
    setSceneTypeId: setSceneGroupId,
    setCurrentScene,
    setSelectedSceneId,
    setSetId,
    setWallColor,
    setSelectedItemsPerIndex,
    setSelectedItemsPerIndexRight,
    queryParams,
    setQueryParams,
    setOpenFabricMode,
  } = useData();
  const [loading, setLoading] = useState(true);

  const customerSet = new URLSearchParams(window.location.search).get('customerSet');
  let userId =
    new URLSearchParams(window.location.search).get('userId') ||
    new URLSearchParams(window.location.search).get('user_id') ||
    new URLSearchParams(window.location.search).get('set_id') ||
    process.env.REACT_APP_DEFAULT_USER_ID;

  if (userId === 'null') userId = null;
  const fabricIdHotspot1 = new URLSearchParams(window.location.search).get('hs1');
  const fabricIdHotspot2 = new URLSearchParams(window.location.search).get('hs2');
  const fabricIdHotspot3 = new URLSearchParams(window.location.search).get('hs3');
  const sceneIndex = new URLSearchParams(window.location.search).get('scene') || 0;
  const sceneGroupId = new URLSearchParams(window.location.search).get('sceneTypeId'); // 7467
  const designFilter = new URLSearchParams(window.location.search).get('ds_filter');
  const colorFilter = new URLSearchParams(window.location.search).get('c_filter');
  const patternFilter = new URLSearchParams(window.location.search).get('p_filter');
  const collectionFilter = new URLSearchParams(window.location.search).get('col_filter');
  const language = new URLSearchParams(window.location.search).get('lang') || localStorage.getItem('i18nextLng');
  const openFabricMode =
    new URLSearchParams(window.location.search).get('openFabricMode') || localStorage.getItem('openFabricMode');

  useEffect(() => {
    let colors = [];
    let designs = [];
    let patterns = [];
    let collections = [];
    setQueryParams(window.location.search);

    if (patternFilter) {
      const tempColletion = JSON.parse(patternFilter);
      patterns = Array.isArray(tempColletion) ? tempColletion.map((item) => item.toString()) : [];
    }

    if (collectionFilter) {
      const tempColletion = JSON.parse(collectionFilter);
      collections = Array.isArray(tempColletion) ? tempColletion.map((item) => item.toString()) : [];
    }

    if (colorFilter) {
      const tempColletion = JSON.parse(colorFilter);
      colors = Array.isArray(tempColletion) ? tempColletion.map((item) => item.toString()) : [];
    }

    if (designFilter) {
      const tempColletion = JSON.parse(designFilter);
      designs = Array.isArray(tempColletion) ? tempColletion.map((item) => item.toString()) : [];
    }

    const filter = {
      colorFilter: colors,
      collectionFilter: collections,
      specialAttributesFilter: [],
      designFilter: designs,
      patternFilter: patterns,
    };
    setFilterItems(filter);
  }, [collectionFilter, colorFilter, designFilter, patternFilter, queryParams, setFilterItems, setQueryParams]);

  useEffect(() => {
    const initializeFabricsDataSet = (data) => {
      const dataset = [];

      Object.values(data.product_groups).map((entry) =>
        dataset.push({
          [entry.id]: '',
        }),
      );
    };

    const initCurrentProductGroup = (data, scenes) => {
      const dataset = [];
      const selectedFabricsObject = [];
      let productGroupCounter = 0;
      let currentSortKey = 0;

      Object.entries(data.fabrics).map((entry) => {
        if (entry[1].length !== 0) productGroupCounter += 1;
        return entry[1].map((fabricArray) => {
          currentSortKey = scenes[0].activeFabrics.indexOf(fabricArray.id.toString());

          if (!selectedFabricsObject[productGroupCounter]) {
            selectedFabricsObject[productGroupCounter] = {
              fabric: fabricArray,
              subgroupId: entry[0],
              sortKey: currentSortKey,
            };
          }

          if (selectedFabricsObject[productGroupCounter].sortKey > currentSortKey) {
            selectedFabricsObject[productGroupCounter] = {
              fabric: fabricArray,
              subgroupId: entry[0],
              sortKey: currentSortKey,
            };
          }

          return dataset.push({
            product: fabricArray,
            isFavorite: false,
            subgroupId: entry[0],
          });
        });
      });

      let currentSceneIndex = 0;
      if (sceneIndex && sceneIndex < scenes.length) {
        currentSceneIndex = sceneIndex;
      }

      const numberOfHotspots = scenes[currentSceneIndex].scene.hotspots.filter((item) => item.product_group !== '7318')
        .length;

      const indexedItems = [];

      const hotspot1 = scenes[currentSceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS1')[0]
        .product_group;

      const hotspot2 =
        numberOfHotspots >= 2
          ? scenes[currentSceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS2')[0].product_group
          : '';

      const hotspot3 =
        numberOfHotspots >= 3
          ? scenes[currentSceneIndex].scene.hotspots.filter((hotspot) => hotspot.subfolder === 'HS3')[0].product_group
          : '';

      const fabricHS1 = data.fabrics[hotspot1].filter((obj) => obj.post_title === fabricIdHotspot1);
      const fabricHS2 =
        hotspot2 !== '' ? data.fabrics[hotspot2].filter((obj) => obj.post_title === fabricIdHotspot2) : [];
      const fabricHS3 = hotspot3 ? data.fabrics[hotspot3].filter((obj) => obj.post_title === fabricIdHotspot3) : [];

      if (fabricHS1.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot1)[0].fabric = fabricHS1[0];
      }
      if (hotspot1) indexedItems.push(getSelectedItemById(selectedFabricsObject, hotspot1));

      if (fabricHS2.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot2)[0].fabric = fabricHS2[0];
      }
      if (hotspot2) indexedItems.push(getSelectedItemById(selectedFabricsObject, hotspot2));

      if (fabricHS3.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        Object.values(selectedFabricsObject).filter((entry) => entry.subgroupId === hotspot3)[0].fabric = fabricHS3[0];
      }
      if (hotspot3) indexedItems.push(getSelectedItemById(selectedFabricsObject, hotspot3));

      setCardsMap(dataset);

      setSelectedSceneIndex(+currentSceneIndex);

      const clone1 = JSON.parse(JSON.stringify(indexedItems));
      const clonedIndex = JSON.parse(JSON.stringify(indexedItems));
      setSelectedItemsPerIndexRight(clonedIndex);
      setSelectedItemsPerIndex({ ...clone1 });

      const clone2 = JSON.parse(JSON.stringify(selectedFabricsObject));
      setSelectedItemsRight(clone2.filter((elem) => elem !== null));
      setSelectedItems({ ...selectedFabricsObject });

      if (openFabricMode === 'search' || /^hs[1-9]$/.test(openFabricMode)) {
        setOpenFabricMode(openFabricMode);
      }
      setLoading(false);
    };

    async function fetchData(userSetId) {
      setLoading(true);
      setSetId(userSetId);
      const response = await api.fetchData(customerSet || userSetId, language);
      if (response.status === 200) {
        const { data } = response.data;
        if (data.wallcolor_option.split('\r\n')[0])
          setWallColor(toColor('hex', data.wallcolor_option.split('\r\n')[0]));
        setJsonData(data);
        initializeFabricsDataSet(data);

        return data;
      }
      return null;
    }

    async function loadUserData(id) {
      setSelectColor('#EFC743');
      if (id) {
        const userDataObj = await api.getUserData(id);
        const { userData } = userDataObj.data.data;
        if (userDataObj.status === 200) {
          setUserData(userDataObj);
          setUserId(id);
          const highlightColor =
            userData.highlightcolor && userData.highlightcolor !== '' ? userData.highlightcolor : '#EFC743';
          const col = highlightColor !== 'undefined' ? highlightColor : '#EFC743';
          setSelectColor(col);
          setBanner(userData.show_embedding_banner === 'true' || userData.show_embedding_banner === '');
          setPrivacyPolicy(userData.show_privacy_policy);
          return userData;
        }
      }
      return null;
    }

    async function initData() {
      const returnUser = await loadUserData(userId);

      const langParam = new URLSearchParams(window.location.search).get('lang');

      if (returnUser && returnUser.userlng && !langParam) {
        i18next.changeLanguage(returnUser.userlng);
      }

      const currentSetId = returnUser && returnUser.setId && returnUser.setId !== '' ? returnUser.setId : '7468';
      const jsonData = await fetchData(currentSetId);

      let { scenes } = jsonData;

      const unique = [...new Set(scenes.sort((a, b) => a.menu_order - b.menu_order).map((item) => item.scene_type_id))];

      if (sceneGroupId) {
        scenes = jsonData.scenes.filter((scene) => scene.scene_type_id === +sceneGroupId);
      } else if (unique && unique.length > 1) {
        scenes = jsonData.scenes.filter((scene) => scene.scene_type_id === +unique[0]);
      }

      scenes.sort((a, b) => parseFloat(a.product_group) - parseFloat(b.product_group));

      if (returnUser && returnUser.customer_scenes && returnUser.customer_scenes !== '') {
        const sceneIds = returnUser.customer_scenes.split(',').map((sceneId) => +sceneId);
        const selectedScenes = [];

        sceneIds.forEach((sceneId) => {
          const scene = scenes.find((el) => el.scene.id === sceneId);
          if (scene) {
            selectedScenes.push(scene);
          }
        });
        scenes = selectedScenes;
        setAllScenes(scenes);
      } else {
        setAllScenes(scenes);
      }
      if (scenes.length > 0) {
        setCurrentScene(scenes[0]);
        setSelectedSceneId(scenes[0].scene.id);

        initCurrentProductGroup(jsonData, scenes);
        setLoading(false);
      }
    }

    initData();
  }, [
    setCardsMap,
    setJsonData,
    setSelectedItems,
    setFilterItems,
    setSelectedItemsRight,
    customerSet,
    sceneIndex,
    fabricIdHotspot1,
    fabricIdHotspot2,
    setSelectedSceneIndex,
    sceneGroupId,
    setAllScenes,
    setSceneGroupId,
    setSelectColor,
    setUserData,
    setUserId,
    setBanner,
    setPrivacyPolicy,
    userId,
    setCurrentScene,
    setSelectedSceneId,
    setSetId,
    fabricIdHotspot3,
    setWallColor,
    setSelectedItemsPerIndex,
    setSelectedItemsPerIndexRight,
    language,
    openFabricMode,
    setOpenFabricMode,
  ]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/compare" element={<SceneComparePage />} />
            <Route path="/shadow" element={<ShadowSimulator />} />
            <Route user={user} path="/profile" element={<UserProfilePage />} />
            <Route user={user} path="/embed" element={<EmbedOptionsPage />} />
            <Route path="/password-reset" element={<PasswordResetPage />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}
